

export default {
    initialColorMode: 'light',
    colors: {
        text: '#000',
        background: '#fff',
        modes: {
            dark: {
                text: '#fff',
                background: '#000',
            },
        },
    },
}